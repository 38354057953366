import { ReactNode, useMemo } from "react";
import classNames from "classnames";

interface ButtonProps {
  text?: string;
  icon?: ReactNode;
  size?: "sm" | "md" | "lg" | "xl";
  color?: "purple" | "indigo" | "teal" | "blue" | "green" | "yellow" | "red";
  variant?: "primary" | "secondary";
  className?: string;
  target?: "_self" | "_blank";
  rel?: ["noopen" | "noreferrer"];
  link?: string;
  block?: boolean;
  children?: ReactNode;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => void;
}

export const Button = ({
  text,
  icon,
  size = "md",
  color = "indigo",
  variant = "primary",
  className,
  link,
  target,
  rel,
  block,
  children,
  type = "button",
  disabled = false,
  onClick,
}: ButtonProps) => {
  const primaryClassname = useMemo(
    () =>
      classNames(
        `flex items-center justify-center font-semibold py-2 px-4 bg-${color}-500 border-2 border-${color}-500 text-white rounded-full border-box hover:opacity-90`,
        {
          "w-full": block,
          "w-fit": !block,
          "text-sm": size === "sm",
          "text-base": size === "md",
          "text-lg": size === "lg",
          "text-xl": size === "xl",
          "gap-4": icon,
        }
      ),
    [block, size, icon, color]
  );
  const secondaryClassname = useMemo(
    () =>
      classNames(
        `flex items-center justify-center font-semibold py-2 px-4 text-${color}-500 border-2 border-${color}-500 rounded-full border-box hover:bg-gray-100`,
        {
          "w-full": block,
          "w-fit": !block,
          "text-sm": size === "sm",
          "text-base": size === "md",
          "text-lg": size === "lg",
          "text-xl": size === "xl",
          "gap-4": icon,
        }
      ),
    [block, size, icon, color]
  );

  if (link) {
    return (
      <a
        className={classNames(
          "select-none",
          {
            [`${primaryClassname}`]: variant === "primary",
            [`${secondaryClassname}`]: variant === "secondary",
          },
          className
        )}
        href={link}
        rel={rel && rel.join(", ")}
        target={target}
      >
        {text}
        {children}
        {icon}
      </a>
    );
  }

  return (
    <button
      className={classNames(
        "select-none",
        {
          [`${primaryClassname}`]: variant === "primary",
          [`${secondaryClassname}`]: variant === "secondary",
        },
        className
      )}
      disabled={disabled}
      onClick={onClick && onClick}
      type={type}
    >
      {text}
      {children}
      {icon}
    </button>
  );
};
