import { useEffect } from "react";

interface HubspotContactFormProps {
  region: string;
  portalId: string;
  formId: string;
  onReady?: () => void;
  onSubmit?: () => void;
}

const HubspotContactForm = ({
  region,
  portalId,
  formId,
  onReady,
  onSubmit,
}: HubspotContactFormProps) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: "#hubspotForm",
        });
      }
    });

    const handleEvents = (event: MessageEvent) => {
      if (
        event.data?.type === "hsFormCallback" &&
        event.data?.eventName === "onBeforeFormInit"
      ) {
        if (onReady) onReady();
      }
      if (
        event.data?.type === "hsFormCallback" &&
        event.data?.eventName === "onFormSubmit"
      ) {
        if (onSubmit) onSubmit();
      }
    };

    window.addEventListener("message", handleEvents);

    return () => window.removeEventListener("message", handleEvents);
  }, [region, portalId, formId]);

  return (
    <div className="w-full">
      <div id="hubspotForm" />
    </div>
  );
};

export default HubspotContactForm;
