import { Button } from "@/components/Button";
import { CursorArrowRaysIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const [t] = useTranslation("global");

  return (
    <div className="w-full flex gap-6 flex-col items-center justify-center px-6 py-20">
      <CursorArrowRaysIcon className="h-24 w-24 text-indigo-600" />
      <div className="text-center">
        <h1 className="text-3xl md:text-5xl font-bold">{t("NotFound.Code")}</h1>
        <h1 className="text-4xl md:text-5xl font-bold">
          {t("NotFound.Title")}
        </h1>
      </div>
      <h3 className="text-stone-600 text-center">{t("NotFound.Subtitle")}</h3>

      <Button
        className="w-full sm:w-40 mt-10"
        variant="primary"
        text="Go back"
        link="/"
      />
    </div>
  );
};
