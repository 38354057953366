import { useEffect, useState } from "react";
import classNames from "classnames";
import ChatSmileIcon from "@/assets/Icons/chat-smile.svg?react";
import { PhoneIcon } from "@heroicons/react/24/outline";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { useTranslation } from "react-i18next";
import { NO_FORMAT_PHONE_NUMBER } from "@/constants/Contact";

export const SupportButton = () => {
  const [t] = useTranslation("global");
  const [open, setOpen] = useState<boolean>(false);
  const [hoverTel, setHoverTel] = useState<boolean>(false);
  const [toolTipOffset, setToolTipOffset] = useState<number>(0);
  const [toolTipText, setToolTipText] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(true);

  const ref = useOutsideClick(() => {
    if (open) setOpen(false);
  });

  useEffect(() => {
    const updateScrollDirection = () => {
      const scrollY = window.scrollY + window.innerHeight;
      const scrollHeight = document.body.scrollHeight;

      if (scrollY + 50 > scrollHeight) {
        setOpen(false);
        setVisible(false);
      } else {
        setVisible(true);
      }
    };

    window.addEventListener("scroll", updateScrollDirection);

    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, []);

  return (
    <div
      className={classNames(
        "fixed right-6 bottom-14 bg-black/65 cursor-pointer rounded-full transition-transform z-50",
        {
          "max-sm:-translate-y-20 -translate-y-10": visible,
          "translate-y-28": !visible,
        }
      )}
    >
      <div
        className={classNames("relative flex flex-col w-14 transition-all", {
          "h-[132px]": open,
          "h-14": !open,
        })}
      >
        <div
          style={{
            transform: `translate(${0}px, -${toolTipOffset}px)`,
            opacity: hoverTel ? "100%" : "0%",
          }}
          className="hidden md:flex h-8 w-max transition-all rounded bg-black text-white absolute right-16 bottom-0 px-4 items-center select-none"
        >
          <p className="text-sm">{toolTipText}</p>
        </div>

        <a
          href={`tel:${NO_FORMAT_PHONE_NUMBER}`}
          onMouseEnter={() => {
            setToolTipOffset(90);
            setToolTipText(t("FloatingButton.Phone"));
            setHoverTel(true);
          }}
          onMouseLeave={() => setHoverTel(false)}
          className={classNames(
            "flex absolute items-center justify-center bottom-0 w-14 h-14 bg-black hover:bg-gray-800 text-white rounded-full transition-all z-30",
            {
              "translate-y-0": !open,
              "-translate-y-20": open,
            }
          )}
        >
          <PhoneIcon className="w-7 h-7" />
        </a>
        <div
          ref={ref}
          className={classNames(
            "flex absolute items-center justify-center bottom-0 scale-105 w-14 h-14 rounded-full z-50 transition-all",
            {
              "bg-black text-white border-[1px] border-gray-800": !open,
              "bg-white text-black": open,
            }
          )}
          onClick={() => setOpen(!open)}
        >
          <ChatSmileIcon className="w-7 h-7" />
        </div>
      </div>
    </div>
  );
};
