import { useEffect, useRef } from "react";

export const useOutsideClick = (callback: () => void) => {
  const callbackRef = useRef<(() => void) | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleClick(e: any) {
    if (
      innerRef.current &&
      callbackRef.current &&
      !innerRef.current.contains(e.target)
    )
      callbackRef.current();
  }

  useEffect(() => {
    if (callbackRef) callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  return innerRef;
};
