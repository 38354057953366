import { StrictMode, useEffect } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { createRoot } from "react-dom/client";
import Cookies from "universal-cookie";

// Components
import { I18nextProvider } from "react-i18next";

// internationalization
import global_en from "@/translation/En/global.json";
import global_es from "@/translation/Es/global.json";
import i18next from "i18next";

import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";
import { SupportButton } from "@/components/SupportButton";
import "./index.css";

import { SendolaBlackPage } from "@/pages/sendola-black";
import NotFound from "@/pages/NotFound";

const cookies = new Cookies();

const DEFAULT_LANGUAGE = "en";

i18next.init({
  interpolation: { escapeValue: false },
  lng: cookies.get("lang") || DEFAULT_LANGUAGE,
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

const Layout = () => {
  const location = useLocation();
  const pathname = location?.pathname;

  useEffect(() => {
    scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <div className="flex-1 flex">
        <Outlet />
      </div>
      <SupportButton />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        path: "",
        element: <SendolaBlackPage />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <I18nextProvider i18n={i18next}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </StrictMode>
);
